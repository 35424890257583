import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ImageFadeIn from "react-image-fade-in";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../components/layout";
import photo from '../../static/images/kontakt.jpg'
import { Helmet } from "react-helmet";


const Kontakt = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [ref, setRef] = useState('');
  const [desc, setDesc] = useState('');
  const [type, setType] = useState('Svatba');

  const types = ['Svatba', ' Ples', 'Rodinné focení', 'Vánoční focení', 'Jiné']

  const notify = (message) => toast.error(`${message}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notifySuccess = (message) => toast.success(`${message}`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const sendMessage = () => {
    if (name.length === 0) {
      notify('Jméno musí být vyplněno!');
    }
    else if (phone.length === 0) {
      notify('Telefon musí být vyplněn!');
    }
    else if (email.length === 0) {
      notify('Email musí být vyplněno!');
    } else {
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Port: 2525,
        Username: "jakub.psenda@gmail.com",
        Password: "4A5890E5FE54E5D6C7BA698FE947B01DFF71",
        To: 'info@martinpetrovicky.cz',
        // To: 'martinpetrovicky25@seznam.cz',
        From: "jakub.psenda@gmail.com",
        Subject: "Zpráva z formuláře webu martinpetrovicky.cz",
        Body: `
        <h1>Máš novou zprávu z webového formuláře.</h1>
        <br/>
        <p>Jméno: <b>${name}</b></p>

        <p>Telefon: <b>${phone}</b></p>

        <p>Email: <b>${email}</b></p>

        <p>Jak se o tobě dozvěděli: ${ref}</p>

        <p>Datum události: ${date}</p>

        <p>Typ události: ${type}</p>

        <p>Zpráva: ${desc}</p>

        <br/>
        <br/>
        S pozdravem, Já
        
        `
      }).then(
        notifySuccess("Zpráva byla úspěšně odeslána.")
      );

    }
  }

  const [loadedWidth, setLoadedWidth] = useState(false)

  useEffect(() => {
    if (window.screen && window.screen.width) {
      setLoadedWidth(window.screen.width < 1700)
    }
  }, [])

  return (
    <Layout>
              <Helmet>
          <title>Kontakt | Martin Petrovický | Foto a video</title>
          <meta name="description" content="Zabívám se tvorbou videii a focením všech možných událostí. Na mých stránkách si můžete prohlednou ukázku mé tvorby, přečíst reference či mě rovnou kontaktovat."></meta>
      </Helmet>
      <div style={{ backgroundColor: 'rgb(251, 250, 247)' }}>
        <ToastContainer />
        {/* <div className="container"> */}
        <div className="row w-100 m-0">
          <div className={`"col-12  ${loadedWidth ? "col-lg-7" : "col-lg-6"}  d-flex align-items-center p-0`}>
            <ImageFadeIn
              opacityTransition={1.5}
              className={"w-100 pe-0 pe-md-1 pe-lg-3 m-0"}
              style={{ maxHeight: "100% !important" }}
              src={photo}
              alt="kontakt fotka"

            />
          </div>
          <div className={`col-12 ${loadedWidth ? "col-lg-5" : "col-lg-6"} d-flex justify-content-center  align-items-center my-5 my-sm-4 my-md-3 my-lg-5`}>
            <div className="col-12 col-md-12 w-100">
              {/* <div className=" mt-5 pt-5"> */}
              <h3>Rád cestuji, takže není problém za Vámi dojet do různých koutů ČR, ale i do zahraničí.</h3>
              <div className="mb-3 mt-3">
                <p className="m-0">Vaše jméno*</p>
                <input className="w-100" type="text" value={name} onChange={e => setName(e.target.value)} />
              </div>
              <div className="mb-3">
                <p className="m-0">Váš telefon*</p>
                <input className="w-100" type="text" value={phone} onChange={e => setPhone(e.target.value)} />
              </div>
              <div className="mb-3">
                <p className="m-0">Váš email*</p>
                <input className="w-100" type="text" value={email} onChange={e => setEmail(e.target.value)} />
              </div>

              <div className="mb-3 w-100" >
                <p className="m-0">Jak jste se o mně dozvěděli?</p>
                <input className="w-100" type="text" value={ref} onChange={e => setRef(e.target.value)} />
              </div>

              <div className="mb-3 w-100">
                <p className="m-0">Datum události</p>
                <Form.Control style={{ backgroundColor: 'rgb(251, 250, 247)' }} className="w-100 rounded-0" type="date" name='date' onChange={e => setDate(e.target.value)} />
              </div>

              <div className="mb-3 w-100" >
                <p className="m-0">Poznámka</p>
                <textarea className="w-100" rows="2" value={desc} onChange={e => setDesc(e.target.value)}></textarea>
              </div>

              <div className="mt-3">
                {types.map(item =>
                  <span key={item} className="pe-2" >
                    < input
                      className="me-2"
                      type="radio"
                      value={item}
                      name={item}
                      key={item}
                      checked={item === type ? true : false}
                      onChange={() => setType(item)}
                    />

                    {item}

                  </span>

                )}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button id="button-send" className="w-50 text-dark" onClick={() => sendMessage()}>
                  Odeslat
                </button>
              </div>

              <div className="row mt-5">
                <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-md-0 my-1 my-md-2">
                  <div>
                    <h4 className="text-center">+420 777 668 104</h4>
                  </div>
                </div>
                <div className="col-12 col-md-6  d-flex justify-content-center">
                  <div>
                    <h4 className="text-center">info@martinpetrovicky.cz</h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout >
  )
};

export default Kontakt;
